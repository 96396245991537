import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Communication Consulting" />
    <section className="splash">
      <img src="logo.svg" className="splash__logo" alt="Dion Benetatos Communications Consulting" />
      <div class="splash__actions">
        <a href="mailto:hello@dionbenetatos.com">hello@dionbenetatos.com</a>
      </div>
    </section>
  </Layout>
)

export default IndexPage
